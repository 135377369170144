import { Strapi4RequestParams } from "@nuxtjs/strapi/dist/runtime/types";
import { useNotifications } from "~/utilities/data/notifications";

export const getCookie = (name: string) => {
    let nameEQ = name + "=";
    let ca = document.cookie.split(';');
    for (let x in ca) {
        let c = x
        while (c.startsWith(' ')) c = c.substring(1, c.length);
        if (c.startsWith(nameEQ)) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

export type StrapiResponse<T extends object> = {
    data: T,
    meta: {
        pagination: {
            page: number,
            pageSize: number,
            pageCount: number,
            total: number
        }
    }
}

import { stringify } from "qs";
import { getToken } from "../data/activeUser";
import { delay } from "../helper/async";

type method = "find" | "create" | "update" | "delete"
const methodMapping = { "find": "get", "create": "post", "update": "put", "delete": "delete" }

async function serverStrapiUpdate<T extends object>(api: string, id: number, data: any): Promise<StrapiResponse<T>> {
    return serverStrapi("update", api + '/' + id, { data })
}
async function serverStrapiFind<T extends object>(api: string, options?: Strapi4RequestParams): Promise<StrapiResponse<{ id: number, attributes: T }[]>> {
    return serverStrapi("find", api, { options })
}

async function serverStrapiFindOne<T extends object>(api: string, id: number | string, options?: Strapi4RequestParams): Promise<StrapiResponse<{ attributes: T, id: number }>> {
    return serverStrapi("find", api + '/' + id, { options })
}

async function serverStrapiCreate<T extends object>(api: string, data: any): Promise<StrapiResponse<T>> {
    return serverStrapi("create", api, { data })
}

async function serverStrapiDelete<T extends object>(api: string, id: number | string): Promise<StrapiResponse<T>> {
    return serverStrapi("delete", api + '/' + id, {})
}


export async function serverStrapi(method: method, api: string, params: { options?: Strapi4RequestParams, data?: any, silentOnError?: boolean, wait4Backend?: boolean }): Promise<any> {

    const host = process.env.NUXT_PUBLIC_STRAPI_URL || useRuntimeConfig().public.strapiURL;
    let url = new URL(host + '/api/' + api)

    let fetchOptions: RequestInit = {
        headers: {

            'content-type': 'application/json'
        }
    }
    let token = getToken();
    if (token && fetchOptions?.headers) {
        //@ts-ignore
        fetchOptions.headers.Authorization = 'Bearer ' + token;
    }

    if (params?.options) {
        url.search = stringify(params.options, { encodeValuesOnly: true })
    }

    if (method) {
        fetchOptions.method = methodMapping[method];
    }

    if (params?.data) {
        fetchOptions.body = JSON.stringify({ data: params.data })
    }

    // Use window.fetch because it is mocked in nuxt.config.js

    let fetchResult: Response | undefined;
    try {
        fetchResult = await window.fetch(url, fetchOptions);
    } catch (error) {
        if (error instanceof TypeError && error.message === 'Failed to fetch' && params?.wait4Backend) {
            await delay(2000);
            const notificationStore = useNotifications();

            notificationStore.addNotification({ title: 'Wait 4 Backend to come up', message: error.message, type: 'error', time2live: 3000, closable: true });

            return serverStrapi(method, api, params);
        } else if (error instanceof Error) {
            return { error: error.message };
        }

    }
    if (fetchResult) {
        if (fetchResult.status !== 200) {
            if (!params?.silentOnError) {
                const notificationStore = useNotifications();

                let title = "Transaction to the Backend failed!" + new Date().toLocaleString();
                let text = await fetchResult.text();
                let message = 'URL: ' + url.toString() + ' Error:' + text

                notificationStore.addNotification({ title, message, type: 'error', time2live: 15000, closable: true });
            }
            throw new Error("Strapi Query failed" + fetchResult.statusText + " URL: " + url.toString())
        }
        const contentType = fetchResult.headers.get("content-type");



        if (!contentType) throw new Error("Missing content-type");
        if (contentType.includes("application/json")) {
            return await fetchResult.json();
        } else if (contentType.includes("text/plain")) {
            return await fetchResult.text();
        } else if (contentType.includes("spreadsheetml")) {
            const contentDisposition = fetchResult.headers.get('content-disposition');
            const filename = contentDisposition!.split(';')?.[1].split('=')?.[1].replace(/"/g, '');
            const blob = await fetchResult.blob()
            return { blob, filename };
        }

    }
}

export const downloadFile = async (method: method, api: string, params: { options?: Strapi4RequestParams, data?: any, silentOnError?: boolean, wait4Backend?: boolean }): Promise<any> => {
    let { blob, filename } = await serverStrapi(method, api, params);
    const aElement = document.createElement("a");
    aElement.setAttribute("download", filename);
    const href = URL.createObjectURL(blob);
    aElement.href = href;
    aElement.setAttribute("target", "_blank");
    aElement.click();
    URL.revokeObjectURL(href);
}

export const uploadFile = async (apiName: string, objectID: string | number, fieldName: string, file: File) => {
    let form = new FormData();

    const objectNameSingular = apiName.replace(/s$/, '');
    form.append('files', file);
    form.append('ref', `api::${objectNameSingular}.${objectNameSingular}`);
    form.append('refId', objectID + '');
    form.append('field', fieldName);

    const host = process.env.NUXT_PUBLIC_STRAPI_URL || useRuntimeConfig().public.strapiURL;

    let fetchOptions: RequestInit = {
        method: 'post',
        headers: {},
        body: form
    }

    let token = getToken();
    if (token && fetchOptions?.headers) {
        //@ts-ignore
        fetchOptions.headers.Authorization = 'Bearer ' + token;
    }
    return fetch(host + '/api/upload', fetchOptions);


}

export const uploadFileToPath = async (path: string, file: File, infos?: { [k: string]: string }) => {
    let form = new FormData();
    form.append('files', file);
    if (infos) {
        Object.entries(infos).forEach(([key, value]) => form.append(key, value));
    }

    const host = process.env.NUXT_PUBLIC_STRAPI_URL || useRuntimeConfig().public.strapiURL;

    let fetchOptions: RequestInit = {
        method: 'post',
        headers: {},
        body: form
    }

    let token = getToken();
    if (token && fetchOptions?.headers) {
        //@ts-ignore
        fetchOptions.headers.Authorization = 'Bearer ' + token;
    }

    return fetch(host + '/api/' + path, fetchOptions);

}

export const strapiGetObjectByParameters = async (api: string, data: { [k: string]: any }) => {
    let filters = Object.entries(data).map(([key, value]) => ({ [key]: { '$eq': value } })).reduce((acc, curr) => ({ ...acc, ...curr }), {});

    return serverStrapiFind(api, { filters });
}


export { serverStrapiCreate, serverStrapiDelete, serverStrapiFind, serverStrapiFindOne, serverStrapiUpdate };
