export const deepClone = <T>(obj: T): T => {
    if (obj === undefined || obj === null) return obj;
    try {
        return deepCloneIntern(obj);
    } catch (e) {
        console.error("Error Object: ", obj)
        throw e;
    }
}

function deepCloneIntern<T>(obj: T): T {
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }

    const cloned = (Array.isArray(obj) ? [] : {}) as T;

    for (let key in obj) {
        if (Object.hasOwn(obj, key)) {
            if (typeof obj[key] === 'function') {
                cloned[key] = obj[key];
            } else if (obj[key] instanceof Date) {
                cloned[key] = new Date((obj[key] as any).toISOString()) as any
            } else if (obj[key] instanceof RegExp) {
                cloned[key] = new RegExp((obj[key] as any).source, (obj[key] as any).flags) as any
            } else {
                cloned[key] = deepClone(obj[key]);
            }
        }
    }

    return cloned as T;
}