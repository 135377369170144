// Interface automatically generated by schemas-to-ts

import { User } from '../../../../common/schemas-to-ts/User';
import { User_Plain } from '../../../../common/schemas-to-ts/User';
import { AdminPanelRelationPropertyModification } from '../../../../common/schemas-to-ts/AdminPanelRelationPropertyModification';

export enum Type {
  Filter = 'filter',
  UserCourse = 'userCourse',
  GuiSettings = 'guiSettings',}

export interface UserSetting {
  id: number;
  attributes: {
    createdAt: Date;    updatedAt: Date;    publishedAt?: Date;    type: Type;
    settings?: any;
    user?: { data: User };
  };
}
export interface UserSetting_Plain {
  id: number;
  createdAt: Date;  updatedAt: Date;  publishedAt?: Date;  type: Type;
  settings?: any;
  user?: User_Plain;
}

export interface UserSetting_NoRelations {
  id: number;
  createdAt: Date;  updatedAt: Date;  publishedAt?: Date;  type: Type;
  settings?: any;
  user?: number;
}

export interface UserSetting_AdminPanelLifeCycle {
  id: number;
  createdAt: Date;  updatedAt: Date;  publishedAt?: Date;  type: Type;
  settings?: any;
  user?: AdminPanelRelationPropertyModification<User_Plain>;
}
