import { PluginUsersPermissionsUser } from '~/server/backend/types/generated/contentTypes';
import { convertToNoRelation } from '~/utilities/converter/strapiConverter';
import { serverStrapi, serverStrapiCreate, serverStrapiDelete, serverStrapiFind, serverStrapiFindOne, serverStrapiUpdate } from '../strapi/serverStrapi';

import { defineStore } from "pinia";
import { Team_Plain } from '~/server/backend/src/api/team/content-types/team/team';
import { UserRl } from '~/server/backend/src/api/user-rl/content-types/user-rl/user-rl';
import { Type, UserSetting, UserSetting_NoRelations } from '~/server/backend/src/api/user-setting/content-types/user-setting/user-setting';
import { User_Plain } from '~/server/backend/src/common/schemas-to-ts/User';
import type { UserRoles } from '~/server/backend/src/shiftUtils/config/ticket/ticketInterface';
import { ActiveFilter, SortByObject } from '~/settings/table/filter/table';
import { deepClone } from '../converter/clone';
import { delay } from '../helper/async';
import { mergeDeep } from '../helper/deepMerge';
import { makeStrapiBasicOptional, StrapiNotification_Plain } from '../strapi/types';
import { Overwrite } from '../type/helper';

export type ActiveUser = User_Plain & { teams: Team_Plain[], user_settings: UserSetting_NoRelations[], notifications: StrapiNotification_Plain[], first_name?: string, last_name?: string };
export type filterSetting = {
    name: string,
    filter: ActiveFilter[],
    apiPath: string
    sortBy: SortByObject[],
    tableConfigName: string,
    activeColumns?: { key: string }[],
    position?: number,
    storedStatistics?: {
        totalCountCurrent: number,
        totalCountSeen: number,
        idsCurrent: number[],
        idsSeen: number[],
        lastSeen: Date,
        lastUpdated: Date
    }
}

export type userBreadcrumb = {
    tickets: {
        touched: Date,
        ticketId: number
    }[]
}

export type GuiSettings = {
    tableOrder: 'asc' | 'desc',
    theme: 'dark' | 'light' | 'system',
    language: 'en' | 'de' | 'zh',
    defaultTableSize: number,
};

const guiSettingsDefaults: GuiSettings = { tableOrder: 'asc', theme: 'system', language: 'de', defaultTableSize: 12 };

export type UserSettingFilter<T extends Type> = Overwrite<UserSetting_NoRelations, {
    settings: (T extends Type.Filter ? filterSetting
        : T extends Type.UserCourse ? userBreadcrumb
        : T extends Type.GuiSettings ? GuiSettings
        : never)
}>

export const getToken = () => {
    const name = 'token';
    let nameEQ = name + "=";
    let ca = document.cookie.split(';').map(i => i.trim());
    for (let c of ca) {
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

function setCookie(cname: string, value: string, exDays: number) {
    const d = new Date();
    d.setTime(d.getTime() + (exDays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + value + ";" + expires + ";path=/";

    const nuxt = useNuxtApp();
    nuxt._cookies = nuxt._cookies || {};
    (nuxt._cookies as any)[cname] = value;

}

export const useActiveUser = defineStore('activeUser', () => {
    const userStore = ref<ActiveUser>();
    const changes = ref(false);
    const roles = ref<{ id: number, name: string, description?: string }[]>([]);
    const guiSettings = ref<GuiSettings>({ ...guiSettingsDefaults });
    let guiSettingsID: number | null = null;

    let loading: Promise<any> | null = null;

    const load = async (options?: { force?: boolean, silentOnError?: boolean, wait4Backend?: boolean }): Promise<void> => {
        if (!options?.force && userStore.value) return;

        if (options?.force || loading === null) {
            try {
                loading = serverStrapi('find', 'users/me', { options: { populate: { notifications: { populate: { user: true } }, user_settings: true, teams: true } }, silentOnError: options?.silentOnError || false, wait4Backend: options?.wait4Backend }) as any
                userStore.value = await loading;
                if (!userStore.value) return;
                let roleData = await serverStrapiFind<UserRl["attributes"]>('user-rls', { filters: { users_permissions_users: userStore.value.id } })
                roles.value = roleData.data.map(i => { return { id: i.id, name: i.attributes.name, description: i.attributes.description } })
                let settings = await getGuiSettingsIntern();
                guiSettingsID = settings.id;
                guiSettings.value = settings.settings;
                loading = null;
            } catch (e) {
                console.error(e)
            }
            delay(60000).then(() => {
                if (isLoggedIn.value)
                    load({ force: true })
            });

        } else {
            return await loading;
        }

    }

    const isLoggedIn = computed(() => !!userStore.value);

    const user = computed((): ActiveUser => {
        if (!userStore.value) throw new Error('User not loaded!');
        return userStore.value;
    })

    const userRoles = computed((): { id: number, name: string, description?: string }[] => { return roles.value; })
    const userRolesName = computed((): string[] => { return roles.value.map(i => i.name); })

    const hasRole = (role: UserRoles | number): boolean => {

        if (typeof role === 'number') return !!roles.value.find(i => i.id === role)
        if (userRolesName.value.includes('super_admin')) return true;
        return userRolesName.value.includes(role);
    }

    const hasRoleOneOf = (roleList: (UserRoles | number)[]): boolean => {
        for (let role of roleList) {
            if (roles.value.find(i => typeof role === 'number' ? i.id : i.name === role)) {
                return true;
            }
        }
        return false;
    }

    const userSettingByType = <T extends Type>(type: T, options?: { noClone?: boolean }): UserSettingFilter<T>[] => {
        if (!userStore.value) throw new Error('User not loaded!');

        let result: UserSettingFilter<T>[] = [];
        for (let setting of userStore.value.user_settings) {
            if (setting.type === type) {
                result.push(setting as UserSettingFilter<T>);
            }
        }

        if (options?.noClone) return result;
        return deepClone(result);
    }

    const userSettingsUpdate = async <T extends Type>(settings: UserSettingFilter<T>, options?: { doNotUpdateLocal?: boolean }): Promise<void> => {
        if (options?.doNotUpdateLocal && !userStore.value) {
            console.warn("Not loaded yet!")
            return;
        }
        if (!settings.id) throw new Error('No id provided!');
        if (!userStore.value) throw new Error('User not loaded!');
        if (!userStore.value.user_settings.find(i => i.id === settings.id)) throw new Error('User setting not found! ID: ' + settings.id);

        await serverStrapiUpdate('user-settings', settings.id, { ...settings, user: userStore.value.id });
        if (!options?.doNotUpdateLocal) {
            userStore.value.user_settings = [...userStore.value.user_settings.filter(i => i.id !== settings.id), settings];
        }
        changes.value = !changes.value
    }

    const userSettingsAdd = async <T extends Type>(settings: makeStrapiBasicOptional<UserSettingFilter<T>>): Promise<void> => {
        if (!userStore.value) throw new Error('User not loaded!');
        let result = await serverStrapiCreate<UserSetting>('user-settings', { ...settings, user: userStore.value.id });
        userStore.value.user_settings.push(convertToNoRelation(result));
        changes.value = !changes.value
    }

    const userSettingsDelete = async (settings: { id: number }): Promise<void> => {
        if (!settings.id) throw new Error('No id provided!');
        if (!userStore.value) throw new Error('User not loaded!');
        if (!userStore.value.user_settings.find(i => i.id !== settings.id)) throw new Error('User setting not found!');

        await serverStrapiDelete('user-settings', settings.id);
        userStore.value.user_settings = userStore.value.user_settings.filter(i => i.id !== settings.id);
        changes.value = !changes.value
    }

    const getActiveTheme = (): "darkTheme" | "lightTheme" => {
        let theme = guiSettings.value.theme;

        if (theme === "system") {
            let pref = usePreferredColorScheme()
            if (pref.value === "light" || pref.value === "dark") theme = pref.value;
        }

        if (theme === "dark") return "darkTheme";
        if (theme === "light") return "lightTheme";

        return "lightTheme"
    }

    const touchTicket = async (ticketId: number): Promise<void> => {
        await load();
        const settings = userSettingByType(Type.UserCourse)?.[0] || { type: Type.UserCourse, settings: { tickets: [] } } as Partial<UserSettingFilter<Type.UserCourse>>;
        settings.settings.tickets.unshift({ touched: new Date(), ticketId });

        settings.settings.tickets = settings.settings.tickets.filter((t, i, a) => {
            return a.findIndex(t2 => t2.ticketId == t.ticketId) === i
        }).slice(0, 30);

        if (settings.id) {
            userSettingsUpdate(settings)
        } else {
            userSettingsAdd(settings)
        }
    }

    const platformType = computed(() => {
        if (navigator.userAgent.match("Android")) return "mobile";
        return "desktop";
    })

    const setToken = (token: string) => {
        setCookie("token", token, 1)
    }

    const deleteNotification = async (id: number): Promise<void> => {
        if (!userStore.value) throw new Error('User not loaded!');
        if (!userStore.value.notifications.find(i => i.id === id)) throw new Error('Notification not found!');
        await serverStrapiDelete('notifications', id);
        userStore.value.notifications = userStore.value.notifications.filter(i => i.id !== id);
    }

    const getGuiSettingsIntern = async (): Promise<UserSettingFilter<Type.GuiSettings>> => {
        let settingsLoaded = userSettingByType(Type.GuiSettings, { noClone: true })?.[0];
        if (!settingsLoaded) {
            await userSettingsAdd({ type: Type.GuiSettings, settings: guiSettingsDefaults });
            settingsLoaded = userSettingByType(Type.GuiSettings, { noClone: true })?.[0];
        } else {
            settingsLoaded = mergeDeep({ settings: guiSettingsDefaults }, settingsLoaded as Partial<typeof settingsLoaded>) as typeof settingsLoaded;
        }

        return settingsLoaded;
    }

    const getAvailableLanguages = () => {
        return useNuxtApp().$i18n.availableLocales
    }

    const setGuiSettings = () => {
        useNuxtApp().$i18n.locale.value = guiSettings.value.language;
    }

    const getGuiSettings = () => {
        return guiSettings;
    }

    const saveGuiSetting = (): void => {
        setGuiSettings();
        if (loading || !guiSettingsID) return;
        let x = { id: guiSettingsID, type: Type.GuiSettings, settings: guiSettings.value };
        userSettingsUpdate(x as UserSettingFilter<Type.GuiSettings>, { doNotUpdateLocal: true });
    }

    watch(() => guiSettings.value, saveGuiSetting, { deep: true, flush: "sync" });

    return {
        user,
        userRoles,
        userRolesName,
        isLoggedIn,
        load,
        hasRole,
        hasRoleOneOf,
        userSettingByType,
        userSettingsUpdate,
        userSettingsAdd,
        userSettingsDelete,
        touchTicket,
        deleteNotification,
        getToken,
        setToken,
        platformType,
        getGuiSettings,
        getActiveTheme,
        getAvailableLanguages
    }
});



export const getActiveUser = async (): Promise<PluginUsersPermissionsUser["attributes"] & { id: number }> => {
    let users = localStorage.user as string | undefined;
    if (users) {
        let r = JSON.parse(users);
        if (r.length > 0) return r;
    }

    let result = await serverStrapiFindOne('users', 'me', { populate: ['settings', 'notifications'] }) as any;
    localStorage.user = JSON.stringify(result);

    return result;
}

export const getActiveUserID = async (): Promise<number> => {
    return (await getActiveUser()).id;
}
