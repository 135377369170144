

export function mergeDeep<T extends object>(...objects: T[]): T {
    const isObject = (obj: any): obj is object => obj && typeof obj === 'object';

    type Keys = keyof T;
    return objects.reduce((prev, obj) => {
        Object.keys(obj).forEach((keyString) => {
            const key = keyString as Keys;
            const pVal = prev[key];
            const oVal = obj[key];

            if (Array.isArray(pVal) && Array.isArray(oVal)) {
                //@ts-ignore
                prev[key] = pVal.concat(...oVal);
            }
            else if (isObject(pVal) && isObject(oVal)) {
                prev[key] = mergeDeep(pVal, oVal);
            }
            else {
                prev[key] = oVal;
            }
        });

        return prev;
    }, {} as T);
}